import { Loader } from 'semantic';

import { useSession } from 'stores/session';

import ErrorScreen from 'screens/Error';

export default function SessionSwitch({ children }) {
  const { ready, error } = useSession();
  if (!ready) {
    return <Loader active />;
  } else if (error) {
    return <ErrorScreen error={error} />;
  } else {
    return children;
  }
}
